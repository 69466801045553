import './scripts/common';
import {onFind} from "@c0necto/elements-init-modules-in-scope";

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
//

// on('click', function() {
//     console.log('trigger cliick')
//     }, find('.hero__title'))
//
// trigger('click',find('.hero__title'))

import * as lightbox from './scripts/lightbox';
lightbox.init({
    thumbnail:false,
    closable:true,
    closeOnTap: true

});

import * as imgTeaserSwiper from './scripts/img-teaser-swiper';
import * as accoTeaserSwiper from './scripts/acco-teaser-swiper';
import * as imgContentTeaserSwiper from './scripts/img-content-teaser-swiper';

imgTeaserSwiper.init();
accoTeaserSwiper.init();
imgContentTeaserSwiper.init();

// on('load', function() {
//     imgTeaserSwiper.init();
//     accoTeaserSwiper.init();
//     imgContentTeaserSwiper.init();
// }, window);

import * as animationMoveUp from './scripts/animation-move-up';
animationMoveUp.init();

import * as keepDropdownOpen from './scripts/keep-dropdown-open';
keepDropdownOpen.init();

import * as quickfinderRoom from './scripts/quickfinder-room';
quickfinderRoom.init();

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as typeahead from './scripts/typeahead';
typeahead.init();

import * as datepickerRange from "./scripts/datepicker-range";
onFind('.js-datepicker-range', function (baseElement) {
    datepickerRange.createDatepicker(
        baseElement,
        {
            /*options*/
            mode: "range",
            allowInput: true,
            showMonths: 2,
            disableMobile: "false",
            minDate: "today",
        },{
            base: '.datepicker-range-container',
            input: '.js-datepicker-range__input',
            altField: '.js-datepicker-range__alt-field',
            altFieldRangeTo: '.js-datepicker-range__range-to-alt-field'
        }
    );
});

// if(matchMedia('(min-width: 767px)').matches){
//     typeahead.init();
// }else{
//     on('load', function() {
//         typeahead.init();
//     }, window);
// }